<!--成长值明细-->
<template>
  <div class="balance">
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
    <el-table-column label="获取时间" align="center" prop="addDt">
    </el-table-column>
      <el-table-column label="获取来源" align="center" prop="operateTypeName">
      </el-table-column>
      <el-table-column label="操作人" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.operateType==1?'用户本人':scope.row.name+' | '+ scope.row.mobile}}</span>
        </template>
      </el-table-column>
      <el-table-column label="成长值" align="center" prop="experience">
        <template slot-scope="scope">
        <span>{{ "+"+ scope.row.experience}}</span>
        </template>
    </el-table-column>
    </el-table>
     <!-- 分页 -->
     <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
  </div>
</template>
<script>
import { pickerOptions } from "@/db/objs";
import { getUserExRecord } from "@/api/user/user.js";

export default {
  data() {
    return {
      pickerOptions,
      dateToDate: [], //日期
      tableParams: {
        page: 1,
        pageSize: 10,
        memberId: "",
       
      },
      tableData: [],
      total: 0,
      currentPage: 1,
    };
  },
  created() {
    this.tableParams.memberId = this.$route.query.userId;
  },
  mounted() {
    this.getUserBalanceInfo();
  },
  methods: {
    // 用户账户明细
    getUserBalanceInfo() {

      getUserExRecord(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getUserBalanceInfo();
    },
     // 【监听】表格分页点击
     onCurrentPage(page) {
      this.tableParams.page = page;
      this.getTableList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.pageSize = size;
      this.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.balance {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  margin-bottom: 0.15rem;
}

.search_L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
